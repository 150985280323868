.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.instructions {
  cursor: pointer;
  text-decoration: underline;
}
.wordList {
  flex: 1;
  width: Calc(100% - 20px);
  max-width: 300px;
}
.reviewButton {
  margin-bottom: 50px;
  cursor: pointer;
}
.legalLinks {
  font-size: 0.7em;
  margin-bottom: 10px;
}
.modalContent {
  text-align: left;
}

.modalContent h3 {
  text-align: center;
}
