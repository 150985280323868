.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.navButtons {
  margin-bottom: 50px;
}

.navButtons button {
  cursor: pointer;
}

.container ol {
  list-style-position: inside;
  padding: 0;
  margin: 0;
}

.container ol li {
  background: #eee;
  border-radius: 4px;
  margin: 10px;
  padding: 10px;
}

.itemContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.itemContainer span {
  grid-column: 1 / span 3;
  justify-self: start;
  align-self: start;
}

.itemContainer button {
  grid-column: 1 / span 3;
  justify-self: end;
  align-self: end;
}

.itemContainer img {
  max-height: 50px;
  max-width: 75px;
  justify-self: center;
  align-self: center;
}
