.Moon {
  column-count: auto;
  column-width: 300px;
  column-gap: 0;
}

.Moon img {
  background-color: #eee;
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  width: 100%;
}
