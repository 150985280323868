.login {
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.signupLink {
  cursor: pointer;
  text-decoration: underline;
}

.error {
  color: red;
  font-size: 0.8em;
  margin-top: 10px;
  font-style: oblique;
}
