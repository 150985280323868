.container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: #f0e68c;
  border: 1px solid #a19b5e;
  padding: 10px;

  max-width: 325px;

  box-shadow: 5px 5px 15px -5px #00000099;
}
